* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.myElement {
    opacity: 0;
    transition: 2s opacity;
}

.fade-in {
    opacity: 1;
}